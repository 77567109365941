import { FunctionComponent, useState } from 'react';
import I18NService, { msg, msgf } from '@/services/isomorphic/I18NService';

import { IPlacement } from '@/services/models/Content';
import { Nullable } from '@/type-utils';

import { InvalidArgumentError } from '@/utils/errors';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { GenericContent } from '../cms-content';
import { SearchBox } from '../Search';

import S from './styles.module.scss';
import { general_errors_notAvailable_bodyMessage1 } from "@/lang/__generated__/ahnu/general_errors_notAvailable_bodyMessage1";
import { general_errors_notFound_bodyMessage1 } from "@/lang/__generated__/ahnu/general_errors_notFound_bodyMessage1";
import { general_errors_headlineMessage } from "@/lang/__generated__/ahnu/general_errors_headlineMessage";
import { general_errors_bodyMessage2 } from "@/lang/__generated__/ahnu/general_errors_bodyMessage2";
import { general_errors_tryNewSearch } from "@/lang/__generated__/ahnu/general_errors_tryNewSearch";

/**
 * Enum that represents different types of pages that can display an error..
 */
export enum ErrorPageType {
  NotFound = 'notFound',
  NotAvailable = 'notAvailable'
}

interface IErrorProps {
  /**
   * Generic content to be rendered in the right side of 404 copy. Using this placement
   * we can add JC surfer image, maintained by UI devs.
   */
  placement: Nullable<IPlacement>;
  /**
   * The given page type for this component.
   */
  pageType: ErrorPageType;
}

/**
 * 'Error' is a component that when a user reaches the page
 * they requested, the URL path provided no information.
 */
export const PageError: FunctionComponent<IErrorProps> = ({
  placement,
  pageType
}) => {
  /**
   * Gets the body message for the error page.
   * @param pageType - The type of error page that is being displayed.
   * @returns A message string to be displayed to the user.
   * @throws If an invalid pageType is given.
   */
  const getBodyMessage = (pageType: ErrorPageType): string => {
    switch (pageType) {
      case ErrorPageType.NotAvailable: {
        return msg(general_errors_notAvailable_bodyMessage1);
      }
      case ErrorPageType.NotFound: {
        return msg(general_errors_notFound_bodyMessage1);
      }
      default: {
        throw new InvalidArgumentError(
          `The ErrorPageType: '${pageType}' is unknown.`
        );
      }
    }
  };

  const body1 = getBodyMessage(pageType);

  /** Whether or not to display the search box. */
  const showSearchBox = useBrandLocaleValue<boolean>(
    () => ({
      default: true,
      AHNU: false
    }),
    []
  );

  return (
    <div className={S.container}>
      <div className={S.content}>
        <h2 className={S.titleText}>{msg(general_errors_headlineMessage)}</h2>
        <span className={S.text}>{body1}</span>
        <div className={S.textContainer}>
          <span className={S.text}>{msg(general_errors_bodyMessage2)}</span>
        </div>
        {showSearchBox && (
          <div className={S.searchContainer}>
            <h3 className={S.searchTitle}>
              {msg(general_errors_tryNewSearch)}
            </h3>
            <SearchBox variant="secondary" />
          </div>
        )}
      </div>

      <div className={S.placementContainer}>
        {placement && <GenericContent placement={placement} />}
      </div>
    </div>
  );
};
