'use client';

import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';

// Import libraries
import { IProduct } from '@/services/models/Product';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { RecommendationContext } from '@/configs/recommendations';

import { Nullable } from '@/type-utils';
import ProductTile from '../../ProductTile';

// Import styles and assets
import S from './styles.module.scss';
import { product_recommendations_title } from "@/lang/__generated__/ahnu/product_recommendations_title";

export interface IRecommendationsProps {
  /**
   * The recommendation context to render.
   */
  context: RecommendationContext;
}

const DEFAULT_VARIANT = 'recommendation';

/** Component that displays product recommendations.  */
const Recommendations: FunctionComponent<IRecommendationsProps> = observer(
  ({ context }) => {
    const [msg, msgf] = useLocaleMessage();
    const { products, title, isRecommendationEnabled, isSlideOnMobile } =
      useRecommendedProducts(context);

    const variant = DEFAULT_VARIANT;

    return (
      <>
        {isRecommendationEnabled && (
          <div
            className={classes(S.container, isSlideOnMobile ? S.slide : S.grid)}
          >
            {title && (
              <div className={S.title}>
                {title ?? msg(product_recommendations_title)}
              </div>
            )}
            <div className={S.tileContainer}>
              {products?.slice(0, 4).map((product, i) => {
                return (
                  <ProductTile
                    key={product.sku}
                    product={product}
                    variant={variant}
                    isSlideTile={isSlideOnMobile}
                  />
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default Recommendations;
