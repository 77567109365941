'use client';

import { FC, useEffect } from 'react';
import type { InteractionDetails } from '@/services/isomorphic/UserInteractionService';
import UserInteractionService from '@/services/isomorphic/UserInteractionService';

export interface IUserInteractionProps {
  /**
   * The interaction details to be sent to be handled and used to make an event.
   */
  interaction: InteractionDetails;
}

/**
 * The user interaction component allows you to make a generic user interaction.
 */
export const UserInteraction: FC<IUserInteractionProps> = ({ interaction }) => {
  useEffect(() => {
    UserInteractionService.makeAction(interaction);
  }, []);

  return null;
};
